body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Sans-Serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: black;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);
    font-size: 14px;
}

* {
    margin: 0;
    padding: 0;
}

div {
    min-height: 0;
    min-width: 0;
}

html, body, #root {
    height: 100%;
}

#root {
    display: flex;
    flex: 1;
    flex-direction: column;
}

a {
    text-decoration: none;
    color: #5942ad;
}

/* drag drop hacks */
/*.drag-elem {*/
/*    position: absolute;*/
/*    top: -1000px;*/
/*    cursor: pointer;*/
/*    width: 280px;*/
/*    height: 160px;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    overflow: hidden;*/

/*    border: 1px solid #5942ad;*/
/*    background-color: rgba(0, 85, 255, 0.4);*/
/*}*/

/*.drag-elem .email {*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*}*/

/* animations */
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.025); opacity: 0.75; }
    100% { transform: scale(1); }
}

@keyframes bounce {
    0% { transform: scale(1); }
    50% { transform: scale(1.1);}
    100% { transform: scale(1); }
}

@keyframes fadeToRight {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        width: 0;
        padding: 0;
        margin: 0;
    }
}